@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", system-ui;
  color: #000104;
}

p {
  font-family: "Poppins", system-ui;
  color: #000104;
  background: none;
}

.container {
  background-color: #fff;
  width: 96%;
  margin: 1.5rem auto 0 auto;
  border-radius: 8px;
  padding: 1rem;

  input{
    &:not(table td input) {
      border: 1px solid #8C8989;
      padding: 12px;
      border-radius: 8px;
      width: 100%;
  
      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    }
    &::-webkit-datetime-edit-fields-wrapper{
      span{
        color: #C2C2C2;
      }
    }
  }
}

button,
input,
select,
option {
  font-family: "Poppins", system-ui;
}

.login {
  display: grid;
  grid-template-columns: 464px 1fr;

  &__left {
    background: rgb(238, 241, 247);
    background: linear-gradient(210deg, rgba(238, 241, 247, 1) 50%, rgba(43, 117, 173, 1) 100%);
    height: 100vh;
    position: sticky;
    top: 0;
  }

  input {
    // padding: 8px 0px 8px 16px;
    border-radius: 8px;
    // border: 1px solid #8C8989;
    font-size: 14px;
    min-width: 240px;

    &::placeholder {
      color: #C2C2C2;
    }
  }

  &__splitter {
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(102, 102, 102, 0), rgba(109, 110, 113, 1), rgba(102, 102, 102, 0));
  }

  &__container {
    width: 74%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
}

.btn {
  border-radius: 8px;
  color: #fff;
  text-align: center;
  min-width: 300px;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  &__primary {
    background-color: #002D62;

    &:hover {
      background-color: #1C2577;
    }
  }

  &:hover {
    color: #fff;
  }
}

.topbar {
  width: 100%;
  height: 48px;
  background: linear-gradient(to right, #00599C, #004376);
  position: sticky;
  top: 0;
  z-index: 5;

  &__container {
    width: 96%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  &__logo {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
  }

  &__profilepic {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #FFF4CF;
    color: #9C7A00;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__profile{
    position: relative;
    &--panel{
      border-radius: 10px;
      padding: 1rem;
      border: 1px solid #DDEBFF;
      background-color: #fff;
      min-width: 250px;
      position: absolute;
      right: 0;
      top: 3rem;
      z-index: 6;
      &-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        color: #707070;
        img{
          width: 16px;
          height: 16px;
        }
      }
    }
  }

}

//My Style

.register {
  width: 35%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  // height: calc(100vh - 80px);
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  &__title {
    font-size: 20px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}

.status-progress-info {
  li {
    color: var(--grey, #6D6E71);
    font-size: 18px;
    font-weight: 400;
    line-height: 21.78px;
  }

  ul {
    margin-top: 23px;
    gap: 32px;
    display: flex;
    flex-direction: column;
  }
}

.topnav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 96%;
  background-color: transparent;
  padding: 10px 0;
  margin: 0 auto;

  &__logo {
    font-weight: 700;
    font-size: 25px;
    color: #fff;
  }

  &__ul {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;

    li {
      p {
        color: #DBDEF8;
      }

      a {
        color: #000000;
        padding: 10px 8px;
        border-radius: 8px;
        background-color: transparent;
        display: flex;
        gap: 10px;
        align-items: center;

        &.active {
          font-weight: 500;
          background-color: #DDEBFF;
        }
      }
    }
  }

  &__profile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    &--name {
      font-size: 20px;
      font-weight: 600;
      color: #fff;
    }

    &--pic {
      background-color: #FFFFFF;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 10%);
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: #8814D0;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--welcome {
      font-size: 12px;
      color: #FFFFFF;
    }

    &--namesec {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
  }

  &__right {
    display: flex;
    gap: 2rem;
  }

  &__notification {
    color: #fff;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.profile {
  display: grid;
  grid-template-columns: 340px 1fr;
  min-height: calc(100vh - 88px);
  overflow: auto;
}

.document {
  &__boxcontainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #D9D9DD;
    padding: 1rem;
  }

  &__boxheader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 14px;
      color: #000104;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }

  &__boxfooter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    p {
      font-size: 10px;
      color: #6D6E71;
      font-weight: 300;
    }
  }

  &__upload {
    position: relative;
    border: 1px solid #D9D9DD;
    border-radius: 8px;
    padding: 10px;

    input {
      position: inherit;
      z-index: 2;
      width: 100%;
      opacity: 0;
    }

    img {
      position: absolute;
      right: 12px;
      top: 10px;
      z-index: 1;
    }

    label {
      font-size: 14px;
      color: #6D6E71;
      position: absolute;
      left: 10px;
      top: 15px;
    }
  }

  .custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
  }

  &__tabcontainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 1.5rem;
  }

  .custom-file-upload:hover {
    background-color: #0056b3;
  }

  &__download {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: none;
    background: none;
    position: absolute;
    right: 2rem;

    span {
      font-size: 14px;
      color: #48B1FF;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: #fff;
    border-radius: 6px;
  }

  &__title {
    font-size: 20px;
    color: #fff;
  }

  &__uploadrow {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    input[type="file"] {
      width: 300px;
      padding: 10px 1rem;

      &::-webkit-file-upload-button {
        position: absolute;
        width: 50px;
        right: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
  }

  &__uploadbtn {
    border: 1px solid #A7A7A7;
    border-radius: 6px;
    padding: 0 20px;
  }

  &__uploadfield {
    display: flex;
    gap: 1rem;
    position: relative;
    width: fit-content;
  }
}

.accauth {
  display: flex;
  height: calc(100vh - 80px);
  align-items: center;
  justify-content: center;
  width: 25%;
  margin: 0 auto;

  &__success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 100%;

    &--icon {
      width: 65px;
      height: 65px;
    }
  }
}

.fillingDetails {
  &__container {
    width: 100%;
    margin: 0 auto;
  }

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D9D9DD;
    padding-bottom: 2rem;
    width: 100%;

    &:not(:first-child) {
      padding-top: 2rem;
    }

    &--btn {
      padding: 10px;
      color: #2834A8;
      border: 1px solid #2834A8;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: flex-start;
      width: 80%;
    }

    &--content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
    }

    &--titlesection {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 95%;
    }

    &--title {
      font-size: 16px;
      color: #000104;
    }

    &--desc {
      font-size: 12px;
      color: #6D6E71;
    }

    &--link {
      align-items: center;
      display: flex;
      gap: 2rem;
      justify-content: flex-start;
      width: 100%;
      button{
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--linktext {
      color: #3c38fe;
      font-size: 14px;
      width: 50%;
      max-width: 50%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &--editbtn {
      border: none;
      background: none;
      display: flex;
      justify-content: flex-start;
      gap: 1rem;
    }

    &--icon {
      background-color: #EEF1F7;
      border-radius: 4px;
      padding: 8px;
    }
  }
}

.calendar__text {
  text-transform: uppercase;
  color: grey;
  opacity: 1;
}

.fileviewer{
  &__content{
    height: calc(100% - 60px);
    padding: 1rem;
    .react-pdf__Document{
      max-height: 100%;
      overflow: auto;
    }
  }
}


.button {
  width: 100%;
  //  height: 50px;
  border-radius: 6px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background-color: #00599C;
  transition: all 200ms ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem !important;
  cursor: pointer;

  &:not(input[type="submit"]) {
    &:hover {
      background-color: #024679;
    }
  }
}

.MuiTabPanel-root {
  padding: 40px 0 0 0 !important;
}

.MuiBox-root {
  .MuiTabs-root {
    /* height: 42px;
    min-height: 42px; */

    .MuiTabs-indicator {
      background-color: transparent !important;
    }

    /* .MuiTabs-scroller {
      height: 42px;
    } */
  }
}

.MuiTabs-flexContainer {
  gap: 2rem;

  .MuiButtonBase-root {
    min-height: 100%;
    background-color: transparent;
    border-radius: 6px;
    color: #6D6E71;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-family: "Poppins", system-ui;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    &.Mui-selected {
      transition: none;
      color: #000104;
      border-bottom-color: #48B1FF;
    }

    &::after {
      width: 100%;
      height: 4px;
      border-radius: 30px 30px 0 0;
    }
  }

  .MuiTab-root {
    text-transform: none;
    font-size: 16px;
  }
}

.accordian {
  background-color: white;
  cursor: pointer;
  text-align: left;
  color: var(--secondary-colour, #8814D0);
  font-size: 20px;
  line-height: normal;
  padding-bottom: 16px;



  &__head {
    display: flex;
    height: 46px;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    background: var(--flow_grey, #EEF1F7);

  }

  &__firstoption {
    color: var(--black, #000104);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 71px;
  }

  &__text {
    display: flex;
    height: 46px;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--flow_grey, #EEF1F7);
    border-right: 1px solid var(--flow_grey, #EEF1F7);
    background: #FFF;
  }


  &__inputbtn {
    color: var(--black, #000104);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    border: none !important;
  }

  &__outerbtn {
    display: flex;
    width: 160px;
    height: 36px;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid var(--divider, #D9D9DD);
    background: #FFF;
  }
}

.automobileExpenses {
  &__inputtext {
    padding-left: 44px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-self: stretch;
    border: none !important;
  }

  &__calendar {
    margin-left: 120px;
    color: 1px solid var(--divider, #D9D9DD);
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem 0 0 0;

  &__btn {
    border-radius: 8px;
    min-width: 136px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    padding: 0 10px;

    &--reset {
      background-color: #FFFFFF;
      color: #48B1FF;
      border-color: #48B1FF;
    }

    &--cancel {
      background-color: #DBDEF8;
      color: #333333;
      border-color: transparent;
    }

    &--save {
      background-color: #48B1FF;
      color: #FFFFFF;
      border-color: transparent;
    }

    &--next{
      background-color: #0C9200;
      color: #FFFFFF;
      border-color: transparent;
      &.disabled{
        background-color: #e5e5e5;
        color: #a5a5a5;
        cursor: not-allowed;
      }
    }

    &--outlined {
      background-color: transparent;
      color: #24A0FE;
      border-color: #24A0FE;
    }
    &--delete {
      background-color: red;
      color: #ffff;
      border-color: transparent;
    }
  }
}

.submission {
  &__box {
    padding: 12px 41px;
    gap: 10px;
    border-radius: 8px 8px 8px 8px;
    background: var(--white, #FFFFFF);
    border: 1px solid #2834A8;
    color: #2834A8;
    align-items: center;

  }

  &__save {
    color: #FFFFFF;
    background-color: #2834A8;
    align-items: center;

    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
  }


  &__cancelbox {
    padding: 12px 41px;
    gap: 10px;
    border-radius: 8px 8px 8px 8px;
    background-color: #DBDEF8;
    color: #2834A8;
    align-items: center;
  }

  &__adjust {
    margin-left: 400px;
  }
}

.submitting {
  padding: 1rem 0;
  &__screen {
    border-radius: 14px;
    opacity: 0.4px;
    background: #D9D9D9;
    margin-top: 24px;
  }

  &__textsubmit {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: -webkit-center;
  }

  &__termstext {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: var(--grey, #6D6E71);
    line-height: 24px;

  }
}

.ul {
  list-style-type: disc;
  padding-left: 13px;
}

.dependent {
  &__table {
    border: 1px solid var(--divider, #D9D9DD);
    width: 100%;
  }

  &__tabledata {
    border-right: 1px solid var(--divider, #D9D9DD);
    background-color: #fbfbff;
    font-size: 12px;
    padding: 0 5px;
    height: 33px;
    outline: none;

    &:first-child {
      padding: 0 10px;
    }

    select {
      outline: none;
    }
  }

  &__tableselection {
    border: none !important;
    background-color: #fbfbff;
    color: #adaaaa;
    width: 96px;
  }

  &__tableinput {
    background-color: #fbfbff;
    width: 100%;
    height: 100%;
    padding: 0 5px;
    outline: none;
  }

  &__addbtn {
    height: 24px;
    width: 78px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
    background-color: #DBDEF8;
    color: #00599C;
  }

  &__bankdata {
    border: 1px solid var(--divider, #D9D9DD);
    font-size: 12px;
    height: 32px;
    padding-left: 8px;
    width: 180px;
    border-radius: 4px;
  }
}

.auth {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__resend {
    font-weight: 700;
    font-size: 14px;
    color: #6D6E71;
    text-align: center;
  }

  &__title {
    font-size: 24px;
  }

  input {
    border: 1px solid #ACACAC;
    padding: 1rem;
    border-radius: 6px;
  }
}

.user {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 1rem;
  width: 100%;

  &__card {
    background-color: #D9D9D9;
    border-radius: 8px;
    padding: 15px 10px;
  }

  &__status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
  }

  &__financial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // align-items: center;
    &--steps {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }

    &--step {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &--stepno {
      background-color: #48B1FF;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }

    &--status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }

  &__inputgroup {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;

    label {
      position: absolute;
      color: #C2C2C2;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      pointer-events: none;
      transition: 200ms ease-in-out;
      padding: 0 3px;
      font-size: 14px;
    }

    input {
      border: 1px solid #8C8989;
      padding: 14px;
      border-radius: 8px;
      width: 100%;
      max-height: 50px;

      &:focus {
        border: 1px solid #00599C;
        outline: none;

        &~label {
          top: 0;
          font-size: 12px;
          background-color: #fff;
          color: #00599C;
          outline: none;
          font-weight: 500;
        }
      }

      &:valid {
        border: 1px solid #00599C;
        outline: none;

        &~label {
          top: 0;
          font-size: 12px;
          background-color: #fff;
          color: #00599C;
          outline: none;
          font-weight: 500;
        }
      }

      /* &:not(:placeholder-shown){
          & + label{
            top: 0;
            font-size: 12px;
            background-color: #fff;
            color: #00599C;
            outline: none;
            font-weight: 500;
          }
      } */
      &::-webkit-inner-spin-button {
        display: none;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
  }
}

.editprofile {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__tax {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &--title {
      font-size: 16px;
      font-weight: 600;
    }

    &--form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;

      input {
        border: 1px solid #8C8989;
        padding: 1rem;
        border-radius: 6px;
        width: 100%;
      }
    }

    &--profilepic {
      @extend .account__profile--complete-profilepic;
      max-width: 156px;
    }

    &--table {
      thead {
        th {
          background-color: #003C69;
          color: #FFFFFF;
          height: 52px;
          padding: 0 10px;
          text-align: left;
          font-weight: 600;

          &:not(:last-child) {
            border-right: 1px solid #E6E6E6;
          }
        }
      }

      tbody {
        td {
          height: 52px;
          padding: 0 10px;
          max-width: 135px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          input {
            max-width: 100%;
            height: 100%;
            background-color: transparent;
            outline: none;

            &:-webkit-calendar-picker-indicator {
              display: none;
            }
          }
        }

        tr {
          &:nth-child(odd) {
            td {
              background-color: #F9F9F9;
            }
          }

          &:nth-child(even) {
            td {
              background-color: #EBEBEB;
            }
          }
        }
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
  }
}

.questionnaire {
  &__info {
    background-color: #EDEDED;
    padding: 10px 8px;
    border-radius: 8px;
    font-style: italic;
    font-size: 14px;
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__question {
    font-size: 16px;
    font-weight: 600;
  }
}

.filling-tab-verticalline {
  width: 1px;
  height: 100%;
  background-color: #000;
}

.updateprofile {
  &__title {
    font-weight: 500;
    color: #00599C;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
    width: 100%;

    label {
      font-size: 12px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    input {
      @extend .account__profile--right-input;
    }
  }

  &__table {
    thead {
      th {
        background-color: #EEF1F7;
        color: #000104;
        height: 52px;
        padding: 0 10px;
        text-align: left;
        font-weight: 500;
        font-size: 14px;

        /* &:not(:last-child){
          border-right: 1px solid #E6E6E6;
        } */
        &:first-child {
          width: 75%;
        }

        &:last-child {
          text-align: right;
        }
      }
    }

    tbody {
      td {
        height: 52px;
        padding: 8px 10px;
        max-width: 135px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #EEF1F7;

        input {
          max-width: 100%;
          height: 100%;
          width: 100%;
          background-color: transparent;
          outline: none;

          /* &::-webkit-calendar-picker-indicator{
            display: none;
          } */
          &::-webkit-datetime-edit-fields-wrapper {
            // display: none;
          }

          &::-webkit-inner-spin-button {
            // display: none;
          }

          &[type="number"] {
            text-align: right;

            &::before {
              content: "Amount in USD";
            }
          }
        }

        &:last-child {
          text-align: right;

          input {
            border: 1px solid #D9D9DD;
            padding: 5px;
            border-radius: 4px;

            &::placeholder {
              color: #C2C2C2;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      /* tr{
        &:nth-child(odd){
          td{
            background-color: #F9F9F9;
          }
        }
        &:nth-child(even){
          td{
            background-color: #EBEBEB;
          }
        }
      } */
    }
  }

  &__inputlabel {
    @extend .account__profile--right-inputlabel;
  }

  &__formfield {
    @extend .account__profile--right-formfield;
  }

  &__formrow {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &--bdrbtm {
      border-bottom: 0.5px solid #EEF1F7;
      padding-bottom: 1rem;
    }
  }
}

.account {
  background-color: #F5F8FF;
  width: 100%;
  min-height: calc(100vh - 48px);

  &__container {
    width: 96%;
    margin: 0 auto;
  }

  &__topnav {
    box-shadow: 0 4px 4px 0 rgba(153, 184, 255, 0.15);
    width: 100%;
    background-color: #F5F8FF;
    position: sticky;
    top: 48px;
    z-index: 4;
  }

  &__breadcrumb {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;

    &--left {
      display: flex;
      gap: 1rem;
      font-size: 12px;
    }
  }

  &__left {
    display: flex;
    gap: 2rem;
  }

  &__btn {
    border-radius: 12px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    &--primary {
      background-color: #2BA4FF;
    }
  }

  &__right {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
    position: relative;
  }

  &__dashboard {
    &--bg1 {
      position: absolute;
      left: 5%;
      bottom: 0;
      z-index: 0;
    }

    &--bg2 {
      position: absolute;
      right: 5%;
      bottom: 0;
      z-index: 0;
    }

    &--title {
      font-size: 20px;
      font-weight: 600;
      color: #00599C;
      text-align: center;
    }

    &--steptitle {
      font-weight: 500;
      color: #00599C;
    }

    &--step {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      cursor: pointer;

      &:nth-child(2) {
        padding-top: 5rem;
      }

      &:nth-child(4) {
        padding-top: 2rem;
      }

      &-incomplete {
        opacity: 0.5;
      }
    }

    &--steps {
      display: flex;
      justify-content: center;
      padding: 2rem 0;
      position: relative;
      z-index: 3;
    }

    &--profiledetails {
      background-image: url('../public/assets/images/profile-details-bg.png');
      width: 370px;
      height: 229px;
      padding: 0 0 1.5rem 1.5rem;
    }

    &--profiledetailscontainer {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-end;
      gap: 2rem;
      height: 100%;
      position: relative;
    }

    &--profilename {
      font-size: 20px;
      font-weight: 600;
      color: #fff;
    }

    &--profilepic {
      width: 60px;
      height: 60px;
      background-color: #F5F8FF;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 12px;
      right: 12px;

      p {
        color: #00599C;
        font-weight: 600;
        line-height: 60px;
      }
    }

    &--profileimg {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 100%;
      object-position: top;
      border: none;
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &--profilestatus {
      position: relative;
      width: 100%;
      height: 8px;
      background-color: #F1F1F1;
      display: inline-block;
      border-radius: 10px;
      z-index: 1;
    }

    &--profilestatusbar {
      background: linear-gradient(to right, #373737, #A6B0B7);
      display: inline-block;
      height: 100%;
      border-radius: 10px;
      position: absolute;
      left: 0;
      z-index: 2;
    }

    &--profile {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

    }

    &--allset {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 35%;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      padding: 1rem 0;

      img {
        width: 195px;
        height: 195px;
      }
    }

    &--right{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &-cardsec{
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
      }
      &-card{
        min-height: 217px;
        border: 1px solid #DDEBFF;
        flex: 1;
        padding: 1rem;
        border-radius: 8px;
      }
    }

    &--card{
      &-icon{
        width: 32px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 18px;
          height: 18px;
        }
      }
    }

    &--doc{
      border: 1px solid #DDEBFF;
      padding: 1rem;
      border-radius: 8px;
    }
  }

  &__content {
    display: flex;
    gap: 1rem;
    padding-bottom: 2rem;
  }

  &__profile {
    display: grid;
    grid-template-columns: 20% 80%;
    width: 100%;

    &--left {
      width: 100%;
      display: flex;
      justify-content: center;

      &-wrapper {
        width: 70%;
        margin: 0 auto;
        padding-top: 50px;
      }

      &-profilepic {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid #D9D9DD;
        background-color: #F3E8FA;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 24px;
          color: #8814D0;
          font-weight: 500;
        }
      }

      &-edit {
        background: none;
        border: none;
        position: absolute;
        top: 75px;
        left: 62px;
        width: 30px;
        height: 30px;
      }

      &-name {
        font-size: 20px;
        font-weight: 600;
      }

      &-user {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
      }

      &-ul {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        li {
          a {
            color: #707070;
            display: flex;
            gap: 1rem;
            align-items: center;
            white-space: nowrap;

            &.active {
              color: #24A0FE;

              span {
                background-color: #ACDBFF;
              }
            }

            span {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              background-color: #F0F0F0;
              display: inline-block;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.completed {
              color: #038C11;

              span {
                background-color: #BEFFCC;
              }
            }
          }
        }
      }
    }

    /* &--welcomecontainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      width: 25%;
      height: calc(100vh - 88px);
      align-items: center;
      margin: 0 auto;
  
      img {
        width: 328px;
        height: auto;
      }
    }
  
    &--welcometext {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      justify-content: center;
      align-items: center;
    } */

    &--input-text {
      padding-bottom: 8px;

      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #6D6E71 !important;
    }

    &--textbox {
      padding-bottom: 16px;
    }

    &--row {
      gap: 36px;
    }

    &--label-top {
      padding-bottom: 8px;

      font-size: 12px;
      line-height: 15px;
      color: #6D6E71;
    }

    &--wrapper {
      height: 100px;
      width: 100px;
      border: 1px solid var(--divider, #D9D9DD);
      background: var(--purple, #F3E8FA);
    }

    &--pic {
      color: #8814D0;
      padding: 36px 34px 35px 33px;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      text-align: right;
    }

    &--icon-wrapper {
      padding-left: 153px;
      padding-top: 237px;
    }

    &--username {
      margin-top: 21px;
    }

    &--status {
      margin-top: 78px;
    }

    &--menu {
      &-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 4rem;
        width: 100%;
        @extend .account__profile--left-wrapper;
      }

      &-li {
        list-style-type: none;
      }

      &-a {
        color: #000000;
        ;
        font-size: 16px;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        white-space: nowrap;

        span {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background-color: #48B1FF;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        &.active {
          font-weight: 600;

          span {
            background-color: #00599C;
          }
        }
      }
    }

    &--right {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      position: relative;
      width: 75%;

      &-formwrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
      }

      &-formtitle {
        color: #00599C;
        font-weight: 500;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }

      &-form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1rem;
        column-gap: 2rem;

        .form__input-text {
          color: #6D6E71;
        }
      }

      &-inputlabel {
        font-size: 12px;
        color: #6D6E71;
      }

      &-inputspan{
        font-size: 14px;
        color: #000104;
        @extend .account__profile--right-input;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &-formfield {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &-input {
        padding: 8px 0px 8px 16px;
        border-radius: 8px;
        border: 1px solid #8C8989;
        font-size: 14px;
        font-weight: 500;
        height: 56px;
        width: 100%;

        &::placeholder {
          font-weight: 400;
        }

        &:focus-visible {
          outline-color: #48B1FF;
        }

        &::-webkit-calendar-picker-indicator {
          background-image: url('../public/assets/images/Calendar-icon.svg');
          width: 20px;
          height: 20px;
          padding-right: 8px;
        }
      }

    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;
      border-bottom: 1px solid #717171;

      &-welcome {
        font-size: 18px;
        font-weight: 600;
      }
    }

    &--content {
      padding: 1.5rem;
    }

    &--complete {
      &-incomplete {
        display: flex;
        align-items: end;
        gap: 1rem;
        width: 70%;
      }

      &-profilepic {
        min-width: 156px;
        height: 170px;
        border-radius: 9px;
        background-color: #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__orders {
    &--left {
      padding: 0 2rem;
      @extend .account__profile--left;

      &-ul {
        @extend .account__profile--left-ul;
      }
    }

    &--right {
      &-formtitle {
        @extend .account__profile--right-formtitle;
      }

      &-ques {
        font-size: 14px;
      }

      &-quesinfo {
        background-color: rgba(217, 217, 221, 0.2);
        padding: 10px 8px;
        border-radius: 8px;
        font-style: italic;
        font-size: 14px;
        display: flex;
        gap: 12px;
        align-items: center;
      }

      &-selectinput {
        min-width: 380px;
        padding: 1rem;
        border: 1px solid #8C8989;
        border-radius: 8px;
        appearance: none;

        &:focus-visible {
          outline-color: #48B1FF;
        }
      }

      &-selectbox {
        display: flex;
        align-items: center;
        position: relative;
        width: fit-content;
      }

      &-quesfield {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: fit-content;
      }

      &-questext {
        font-size: 12px;
      }
    }
  }
}

.agent {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  gap: 1rem;

  &__card {
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 4px 16px 0 rgba(188, 187, 255, 0.15);
    padding: 12px;
    width: 100%;

    tspan{
      font-family: "Poppins", system-ui;
      font-size: 14px;
    }

    &--content {
      &-data {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        border-left-width: 4px;
        border-left-style: solid;
        padding-left: 6px;

        &-orders {
          border-left-color: #EDFF1E;
        }

        &-review {
          border-left-color: #1EFF27;
        }

        &-initiated {
          border-left-color: #1EFFAE;
        }

        &-refunded {
          border-left-color: #1E93FF;
        }
        &-revoke {
          border-left-color: #035eb3;
        }


      }
    }

    &--data {
      &-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &-right {
        display: flex;
        justify-content: flex-start;
      }

      &-imgcontainer {
        padding: 5px;
        width: 28px;
        height: 28px;
        border-radius: 5px;
        background-color: #DDEBFF;
      }
    }

    &--taxtype {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      &-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }

      &-row {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 5px;
      }

      &-bar {
        height: 28px;
        border-radius: 5px;
        background-color: #48B1FF;
      }

      &-title {
        font-size: 14px;
        font-size: 14px;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &--status {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &-container {
        border-radius: 8px;
        border: 1px solid #DDEBFF;
        border-left-width: 8px;
        border-left-style: solid;
        width: 100%;
      }

      &-review {
        border-left-color: #C1FFBC;
      }

      &-pending {
        border-left-color: #F5FD9B;
      }

      &-new {
        border-left-color: #BECCFF;
      }

      &-initiated {
        border-left-color: #DDFFF7;
      }

      &-refunded {
        border-left-color: #FCDDFF;
      }

      &-revoke {
        border-left-color: #f8c2c2;
      }

      &-content {
        padding: 10px;
      }

      &-row {
        display: flex;
        justify-content: space-between;
      }

      &-orderno {
        font-size: 14px;
        padding-right: 8px;
        border-right: 1px solid #C0DAFF;
        height: fit-content;
      }

      &-label {
        display: flex;
        gap: 8px;
        border-radius: 32px;
        padding: 10px;

        span {
          font-size: 14px;
          font-weight: 600;
        }

        &-review {
          background-color: #C1FFBC;

          span {
            color: #0C9200;
          }

          svg {
            path {
              fill: #038C11;
            }
          }
        }

        &-pending {
          background-color: #F5FD9B;

          span {
            color: #818C03;
          }

          svg {
            path {
              fill: #818C03;
            }
          }
        }

        &-new {
          background-color: #BECCFF;

          span {
            color: #03218C;
          }

          svg {
            path {
              fill: #03218C;
            }
          }
        }

        &-initiated {
          background-color: #DDFFF7;

          span{
            color: #038C74;
          }

          svg{
            path{
              fill: #038C74;
            }
          }
        }
  
        &-refunded {
          background-color: #FCDDFF;

          span{
            color: #81038C;
          }

          svg{
            path{
              fill: #81038C;
            }
          }
        }

        &-revoke {
          background-color: #ffdbdb;

          span{
            color: #ff25be;
          }

          svg{
            path{
              fill: #ff25be;
            }
          }
        }
      }

      &-namesec {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &-nameinitial {
        background-color: #F8FFCF;
        color: #739C00;
        border-radius: 50%;
        padding: 8px;
      }

      &-actions {
        display: flex;
        gap: 14px;
      }

      &-actionimgsec {
        width: 36px;
        height: 36px;
        border-radius: 5px;
        background-color: #DDEBFF;
        padding: 4px;
        cursor: pointer;

        img {
          width: 28px;
          height: 28px;
        }
      }
    }

    &--appointments {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &__row {
    &--data {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }

    &--orders {
      display: flex;
      gap: 1rem;
    }
  }

  &__right {
    &--appointments {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    &--appointment {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &-container {
        border: 1px solid #D9D9D9;
        padding: 0.75rem;
        border-radius: 10px;
      }

      &-content {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        border-left: 4px solid #00599C;
      }

      &-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        padding-left: 10px;
      }

      &-right {
        display: flex;
        justify-content: flex-start;
      }

      &-imgsec {
        background-color: rgba(181, 215, 255, 0.79);
        width: 28px;
        height: 28px;
        border-radius: 5px;
        box-shadow: 0 4px 16px 0 rgba(188, 187, 255, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
        }
      }

      &-profile {
        font-size: 12px;
        font-weight: 500;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-pending {
        background-color: #F8FFCF;
        color: #739C00;
      }

      &-new {
        background-color: #CFEEFF;
        color: #00519C;
      }

      &-refunded {
        background-color: #FFE6CF;
        color: #9C4B00;
      }

      &-time {
        font-size: 14px;
        font-weight: 600;
        color: #64748B;
      }
    }
  }
}

.order {
  width: 100%;

  &__card {
    @extend .agent__card;
  }

  &__header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #48B1FF;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &--statuscontainer{
      display: flex;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    &--statuspanel{
      display: flex;
      flex-direction: column;
      min-width: 180px;
      background-color: #F5F8FF;
      border-radius: 8px;
      padding: 8px 0;
      position: absolute;
      left: 0;
      top: 2rem;
      margin: 10px 0 0 0;
      z-index: 9999;
      &-status{
        display: flex;
        gap: 8px;
        padding: 10px 1rem;
        transition: all 100ms ease-in;
        &:hover{
          background-color: #dce3f1 !important;
        }
      }
      .order__header--status-new{
        background-color: transparent;
      }
      .order__header--status-pending{
        background-color: transparent;
      }
      .order__header--status-initiated{
        background-color: transparent;
      }
      .order__header--status-review{
        background-color: transparent;
      }
      .order__header--status-refunded{
        background-color: transparent;
      }
      .order__header--status-revoke{
        background-color: transparent;
      }
    }

    &--status {
      padding: 8px;
      border-radius: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      p {
        font-size: 14px;
        font-weight: 600;
      }

      &-review {
        background-color: #E0FFDD;

        p {
          color: #0C9200;
        }

        svg {
          path {
            fill: #0C9200;
          }
        }
      }

      &-pending {
        background-color: #FCFFDD;

        p {
          color: #818C03;
        }

        svg {
          path {
            fill: #818C03;
          }
        }
      }

      &-new {
        background-color: #DDE4FF;

        p {
          color: #03218C;
        }

        svg {
          path {
            fill: #03218C;
          }
        }
      }

      &-initiated {
        background-color: #DDFFF7;

        p {
          color: #038C74;
        }

        svg {
          path {
            fill: #038C74;
          }
        }
      }

      &-refunded {
        background-color: #DDFFF7;

        p {
          color: #81038C;
        }

        svg {
          path {
            fill: #81038C;
          }
        }
      }
      &-revoke {
        background-color: #f1c7c7;

        p {
          color: #f55858;
        }

        svg {
          path {
            fill: #f55858;
          }
        }
      }

      // &-new {
      //   background-color: #FCDDFF;

      //   p {
      //     color: #03218C;
      //   }

      //   svg {
      //     path {
      //       fill: #03218C;
      //     }
      //   }
      // }
    }

    &--profilepic {
      border-radius: 50%;
      width: 75px;
      height: 75px;
      object-fit: cover;
      object-position: top;
    }

    &--left {
      display: flex;
      gap: 1.5rem;
      align-items: center;
    }

    &--userdetails {
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 14px;
    }

    &--schedule {
      background-color: #DDEBFF;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      gap: 10px;

      p {
        font-size: 14px;
        font-weight: 600;
        color: #2E43FF;
      }
    }
  }

  &__singleview {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;

    &--right {
      padding-top: 1rem;
    }
  }

  &__tabs {
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid #DDEBFF;
  }

  &__tabcontainer {
    display: flex;
    padding: 0 1rem;

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child) {
      border-right: 1px solid #C3DCFF;
    }
  }

  &__tab {
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;

    &.active {
      color: #fff;
      background-color: #00599C;
    }
  }

  &__tabscontent {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    &.accordion {
      grid-template-columns: 100%;
    }
  }

  &__contentcard {
    border: 1px solid #DDEBFF;
    border-radius: 7px;

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid #DDEBFF;
    }

    &--actions {
      display: flex;
      gap: 1rem;
    }

    &--action {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
    }

    &--content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 1rem;
      column-gap: 1rem;
      row-gap: 2rem;

      &.accordion {
        grid-template-columns: repeat(5, 1fr);
        border-bottom: 1px solid #DDEBFF;
      }

      &-inner {
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
      }
    }

    &--details {
      display: flex;
      flex-direction: column;
      gap: 8px;
      &-input{
        position: relative;
        &:hover{
          .order__contentcard--details-copy{
            display: block;
          }
        }
        input{
          width: 100%;
          &[type="date"] + .order__contentcard--details-copy{
            right: 28px;
          }
        }
        span{
          width: 100%;
        }
      }
      &-copy{
        @extend .order__contentcard--table-copy;
        right: 10px;
        left: auto !important;
      }
      input[type="radio"]{
        + label{
          font-size: 14px;
        }
      }
    }

    &--title {
      font-size: 14px;
      font-weight: 600;
      color: #00599C;
    }

    &--table {
      width: 100%;

      thead {
        th {
          background-color: #EEF1F7;
          padding: 10px;
          font-size: 14px;
          font-weight: 500;

          &:first-child {
            width: 82%;
            text-align: left;
          }

          &:nth-child(2) {
            text-align: right;
          }
        }
      }

      tbody {
        td {
          border: 1px solid #EEF1F7;
          font-size: 14px;
          padding: 6px;

          &:first-child {
            border-right: none;
            text-align: left;
          }

          &:nth-child(2) {
            border-left: none;
            text-align: right;
            position: relative;

            &:hover {
              .order__contentcard--table-copy {
                display: block;
                cursor: pointer;
              }
            }
          }

          input {
            text-align: right;
            border: 1px solid #D9D9DD;
            border-radius: 4px;
            padding: 4px;
            width: 100%;

            &:focus-visible {
              outline: none;
            }
          }
        }
      }

      &-copy {
        position: absolute;
        left: 15px;
        transform: translateY(-50%);
        top: 50%;
        display: none;
      }
    }

    input {
      font-size: 14px;
      border: 1px solid #D9D9DD;
      border-radius: 4px;
      padding: 3px 6px;
    }
  }

  &__document {
    &--container {
      padding: 1rem;
      border: 1px solid #DDEBFF;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    &--download {
      width: 42px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F1E5FF;
      cursor: pointer;
      border-radius: 3px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    &--section {
      border-radius: 8px;
      border: 1px solid #D9D9DD;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &-field {
        border-radius: 4px;
        border: 1px solid #D9D9DD;
        padding: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &__tabcontent {
    &.accordion-close {
      .order__contentcard--header {
        border-bottom: none;
      }
    }
  }

  &__search {
    position: relative;
    width: 30%;

    img {
      position: absolute;
      right: 1.5rem;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__searchinput {
    padding: 10px;
    border: 1px solid #A5C8FB;
    font-size: 14px;
    border-radius: 6px;
    width: 100%;

    &::placeholder {
      font-weight: 300;
      color: #8C8989;
    }

    &:focus-visible {
      .order__search button {
        display: none;
      }
    }
  }

  &__list {
    padding: 1rem 0;

    &--table{
      max-width: 100%;
      overflow: auto;
      table {
        width: 100%;
  
        thead {
          th {
            background-color: #00599C;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 600;
            padding: 10px;
  
            &:not(:last-child) {
              border-right: 1px solid #fff
            }
  
            &:first-child {
              border-top-left-radius: 6px;
            }
  
            &:last-child {
              border-top-right-radius: 6px;
            }
          }
        }
  
        tbody {
          tr{
            &:nth-child(even){
              background-color: #F1F1F1;
            }
          }
          td {
            padding: 10px;
            font-size: 14px;
  
            a {
              color: #2E43FF;
              font-weight: 600;
            }
  
            &.order__list--actions {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 1rem;
            }
          }
        }
      }
    }

    &--heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #00599C;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
  &__container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    position: absolute;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.MuiPaper-elevation{
  margin: 1rem 0 0 0;
  box-shadow: none !important;
  border: 1px solid #D9D9DD;
  border-radius: 8px !important;
}
@keyframes l3 {to{transform: rotate(1turn)}}

@media (max-width: 767px) {
  .login {
    grid-template-columns: 1fr;
    width: 90%;
    margin: 0 auto;

    &__left {
      display: none;
    }
  }

  .user {
    &__container {
      width: 100%;
      flex-direction: column;
      gap: 3rem;
    }
  }

  .topnav {
    &__ul {
      gap: 5px;

      a {
        gap: 10px;
        font-size: 12px;
      }
    }
  }

  .account {
    &__content {
      flex-direction: column;
    }

    &__dashboard {
      &--steps {
        flex-direction: column;
        gap: 1rem;
      }

      &--step {
        align-items: center;

        &:nth-child(2) {
          padding-top: 0;
        }

        &:nth-child(4) {
          padding-top: 0;
        }
      }
    }

    &__left {
      &.dashboard {
        margin: 0 auto;
      }
    }
  }

  .document {
    &__tabcontainer {
      grid-template-columns: 100%;
    }
  }

  .MuiTabs-root {
    .MuiTabs-scroller {
      max-width: 100%;
      overflow-x: auto;
    }
  }

  .order{
    &__search{
      width: 100%;
    }
    &__list{
      &--table{
        table{
          tr{
            th{
              white-space: nowrap;
            }
            td{
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .pagination{
    flex-direction: column;
    padding: 1rem 0;
    gap: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .login {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .user {
    &__container {
      width: 100%;
    }

    &__form {
      width: 90%;
      margin: 0 auto;
    }
  }

  .account {
    &__content {
      flex-direction: column;
    }
  }
}

@media (max-width: 1024px) {
  .account {
    &__dashboard {
      &--allset {
        width: 100%;
      }
    }

    &__profile {
      grid-template-columns: 100%;

      &--left {
        &-ul {
          flex-direction: row;
          padding-bottom: 1rem;
          max-width: 100%;
          overflow-x: auto;
        }
      }

      &--right {
        width: 100%;
      }
    }
  }

  .agent {
    grid-template-columns: 100%;

    &__row {
      &--data {
        flex-direction: column;
      }

      &--orders {
        flex-direction: column;
      }
    }

    &__card {
      &--status {
        &-row {
          flex-direction: column;
          gap: 1rem;
        }
      }
    }
  }

  .order{

  }
}

.account__orders--right-quesinput {
  padding: 8px 0px 8px 16px;
  border-radius: 8px;
  border: 1px solid #8C8989;
  font-size: 14px;
  font-weight: 500;
  height: 56px;
  width: 50%;
}

//  Pagination Starts
.pagination{
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__right{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    &--btn{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active{
        background-color: #EFF6FF;
      }
      &[disabled]{
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}
//  Pagination Ends

.delete-btn{
  min-width: 100px !important;
  height: 35px !important;
}

.pdf-viewer {
  width: 100%;
  // height: 600px;
  // overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.react-pdf__Page__textContent {
display: none;
}

.react-pdf__Page__annotations {
  display: none;
}

.agent__card--content-data-new{
  border-left-color: #BECCFF;
}

.agent__card--content-data-acccreated{
  border-left-color: #48B1FF;
}


.active-card {
  background-color: #d4e3ff;
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.1);
  transform: scale(1.05); 
  transition: all 0.3s ease;
}


.inactive-card {
  background-color: #ffffff;
  opacity: 0.9; 
  transition: all 0.3s ease;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}
 
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray; 
}